import { Trans } from '@lingui/macro'
import React from 'react'

import kabel from 'assets/fonts/neue-kabel/Neue-Kabel-Bold.ttf'
import logo from 'assets/images/logos/fanfare.png'
import { PAYMENT_PROVIDERS } from 'config'

// This config is based off of think not think-stripe
export const companyInfo = {
  name: 'Fanfare Energy',
  phone: '+1 (855) 955-5303', // TODO: check phone number
  phoneLink: '+18559555303',
  termsLink: 'https://fanfare.energy/terms-and-conditions',
  privacyPolicyLink: 'https://fanfare.energy/terms-and-conditions',
  homepageLink: 'https://fanfare.energy',
  iosAppLink: 'https://itunes.apple.com/us',
  androidAppLink: 'https://play.app.goo.gl/',
  faviconLink: 'https://static.cdn-website.com/runtime/favicon_d1_res.ico',
  forgotPasswordLink: 'https://portal.fanfare.energy/forgot-password',
  paymentProvider: PAYMENT_PROVIDERS.CHASE,
  EFLLink: 'https://fanfare.energy/terms-and-conditions',
  TOSYRACLink: 'https://fanfare.energy/terms-and-conditions',
  customerPortalLink: 'https://portal.fanfare.energy/login',
  callingHours: <Trans>(Mon - Fri 9am - 6pm CST)</Trans>,
  customerPortalName: 'Energy Portal',
  footerCertificate: 'Fanfare Energy',
  supportEmail: 'help@fanfare.energy', // TODO: get email
  noOffersPage_deprecated: 'https://fanfare.energy',
  confirmEmailPassword: true,
}

// Values sampled from mockups
const BRAND_MOCKUP_YELLOW = '#dfd845' // highlights, cards
const BRAND_MOCKUP_ORANGE = '#e58740' // buttons
const BRAND_MOCKUP_NAVY = '#142955' // secondary buttons
const BRAND_MOCKUP_GREY = '#e9edf2' // secondary cards, sliders
const BRAND_MOCKUP_WHITE = '#fefefe' // background
const BRAND_MOCKUP_BLACK = '#0b141c' // text, footers
// eslint-disable-next-line no-unused-vars
const BRAND_MOCKUP_LIGHT_GREY = '#f7f7f7' // secondary background
const BRAND_MOCKUP_DARK_GREY = '#afbbc3' // secondary text

const base = BRAND_MOCKUP_BLACK
const primary = BRAND_MOCKUP_ORANGE
const secondary = BRAND_MOCKUP_BLACK // button text
const blue = BRAND_MOCKUP_GREY
const gray = BRAND_MOCKUP_GREY
const grayMedium = BRAND_MOCKUP_DARK_GREY
const grey = BRAND_MOCKUP_GREY
const green = BRAND_MOCKUP_YELLOW
const greenPromo = BRAND_MOCKUP_YELLOW
const error = '#dd2c00'
const neutral1 = BRAND_MOCKUP_BLACK // offer title text
const neutral2 = BRAND_MOCKUP_BLACK // offer info text
const neutral3 = BRAND_MOCKUP_DARK_GREY // slider text
const neutral4 = BRAND_MOCKUP_BLACK // offer table border
const neutral5 = BRAND_MOCKUP_BLACK // offer box text
const white = BRAND_MOCKUP_WHITE
const footerColor = BRAND_MOCKUP_BLACK
const footerTextColor = BRAND_MOCKUP_WHITE
const darkerPrimary = BRAND_MOCKUP_BLACK // used for hover effect
const background = BRAND_MOCKUP_WHITE
const bannerborderbottom = `${BRAND_MOCKUP_DARK_GREY} 1px solid`
const ratePanel = BRAND_MOCKUP_YELLOW
const rateText = BRAND_MOCKUP_BLACK

export const color = {
  background,
  base,
  primary,
  secondary,
  blue,
  gray,
  grayMedium,
  grey,
  green,
  greenPromo,
  error,
  gradient: `linear-gradient(90deg, ${primary}, ${BRAND_MOCKUP_YELLOW})`,
  link: BRAND_MOCKUP_ORANGE,
  neutral1,
  neutral2,
  neutral3,
  neutral4,
  neutral5,
  white,
  footerColor,
  footerTextColor,
  darkerPrimary,
  transparent: '#ffffff00',
  containerBackground: 'transparent',
  bannerColor: BRAND_MOCKUP_BLACK,
  bannerTextColor: BRAND_MOCKUP_WHITE,
  bannerborderbottom,
  bannerShadow: 'transparent',
  progressBarColor: BRAND_MOCKUP_YELLOW,
  progressBarBackground: BRAND_MOCKUP_WHITE,
  buttonTextColor: BRAND_MOCKUP_WHITE,
  buttonColor: primary,
  buttonBorder: BRAND_MOCKUP_BLACK,
  slider: primary,
  dropdown: base,
  ratePanel,
  rateText,
  offerDetails: BRAND_MOCKUP_BLACK,
  offerShortDescription: ratePanel,
  formField: BRAND_MOCKUP_NAVY,
  circleCheckBackground: primary,
  energyProductIconBackground: primary,
  energyProductIcon: BRAND_MOCKUP_NAVY,
  sliderThumbShadow: 'none',
}

export const assets = {}

export const logoConfig = {
  localPath: logo,
  url: '',
  width: 'auto',
  height: '60px',
  padding: '10px',
  backgroundColor: 'transparent',
  useLocal: true,
  logo,
  progressBarImage: {
    custom: true,
    name: 'think',
    height: '50px',
    width: 'auto',
    stepImageNames: {
      1: 'think-home',
      2: 'think-light',
      3: 'think-lock',
      4: 'think-card',
    },
    filter: 'grayscale(100%)',
  },
  header: {
    height: '60px',
    position: 'left',
  },
}

const baseFamily = "'Helvetica Neue', Helvetica, Arial, sans-serif"
const customFont = 'Inter'

const titleFont = 'Neue Kabel'
const titleFontFamily = `${titleFont}, ${baseFamily}`

export const font = {
  extraBold: '700',
  semiBold: '600',
  medium: '400',
  normal: '350',
  button: '500',
  baseFamily,
  customFont,
  customFamily: `${customFont}, ${baseFamily}`,
  customTitleFamily: titleFontFamily,
}

export const fontFace = `
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@font-face {
  font-family: ${titleFont};
  src: url('${kabel}') format('opentype');
}
`

export const features = {
  DISPLAY_REFERRAL_NAME: true,
  CALL_HEADER: false,
  CALL_FOOTER: true,
  CALL_FOOTER_PHONE: true,
  CALL_FOOTER_EMAIL: true,
  ONLINE_SUPPORT_STATUS_HEADER: false,
  REQUIRE_FULL_SOCIAL: process.env.REACT_APP_REQUIRE_FULL_SOCIAL === 'true',
  HEADER: {
    position: 'relative',
    links: [
      { display: <Trans>About Us</Trans>, link: 'https://fanfare.energy' },
      { display: <Trans>Blog</Trans>, link: 'https://fanfare.energy' },
      { display: companyInfo.phone, link: 'tel:' + companyInfo.phoneLink },
    ],
  },
  BACKGROUND: false,
  LOCALE_HEADER: false,
  V2_THEME: false,
}

export const theme = {
  layout: 'fullPage',
  progressPanel: {
    type: 'minimal',
    logo: false,
    textColor: BRAND_MOCKUP_BLACK,
    activeColor: BRAND_MOCKUP_ORANGE,
    inactiveColor: BRAND_MOCKUP_GREY,
  },
  buttons: {
    borderRadius: '0px',
    shadowed: true,
    primary: {
      color: BRAND_MOCKUP_BLACK,
      backgroundColor: BRAND_MOCKUP_ORANGE,
      borderColor: BRAND_MOCKUP_BLACK,
      shadowColor: BRAND_MOCKUP_BLACK,
      hover: {
        color: BRAND_MOCKUP_ORANGE,
        backgroundColor: BRAND_MOCKUP_WHITE,
        shadowColor: BRAND_MOCKUP_ORANGE,
      },
    },
    secondary: {
      color: BRAND_MOCKUP_WHITE,
      backgroundColor: BRAND_MOCKUP_NAVY,
      borderColor: BRAND_MOCKUP_BLACK,
      shadowColor: BRAND_MOCKUP_DARK_GREY,
      hover: {
        color: BRAND_MOCKUP_NAVY,
        backgroundColor: BRAND_MOCKUP_WHITE,
        shadowColor: BRAND_MOCKUP_YELLOW,
      },
    },
  },
  inputs: {
    borderRadius: '0px',
  },
}
