import styled, { css } from 'styled-components'

import { color as colors } from 'styles/variables'

// Separated out to be appended directly to css to avoid resizing due to wrapper div
export const shadowBefore = css`
  content: '';
  position: absolute;
  top: calc(100% - 10px);
  left: 0;
  z-index: -1;
  width: 100%;
  height: 20px;
  transform-origin: top center;
  transform: skewX(40deg);
  background-color: ${(props) => (props.color ? props.color : colors.base)};
`

/**
 * Wrapper to apply an angled shadow/highlight below an element
 */
export const AngledShadow = styled.div`
  position: relative;
  display: inline-block;

  &::before {
    ${shadowBefore}
  }
`

export default AngledShadow
