import styled from 'styled-components'

import { zIndex, font } from 'styles/variables'
import mq from 'styles/mq'
import { color, logoConfig, features } from 'config/profile'


export const LangContainer = styled.div`
  position: ${props =>
    props.position && props.position === 'right' ? 'absolute' : null};
  align-self: ${props => (props.alignSelf ? props.alignSelf : null)};
  right: 0;
`

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  font-weight: ${font.button ? font.button : font.semiBold};
  font-size: 1.6rem;
`

export const StyledHeader = styled.header`
  z-index: ${zIndex.header};
  position: ${features.HEADER && features.HEADER.position
    ? features.HEADER.position
    : 'sticky'};
  top: 0;
`
export const HeaderBackground = styled.div`
  padding: 0 15px;
  height: ${logoConfig.header ? logoConfig.header.height : '75px'};
  display: flex;
  align-items: center;
  justify-content: ${logoConfig.header && logoConfig.header.position
    ? logoConfig.header.position
    : 'space-between'};
  background: ${color.bannerColor};
  box-shadow: 0 2px 4px ${color.bannerShadow || 'rgba(0, 0, 0, 0.3)'};
  transition: height 0.5s ease;
  border-bottom: ${color.bannerborderbottom
    ? color.bannerborderbottom
    : 'none'};
  ${mq.tablet`
    padding: 0 140px;
    height: ${logoConfig.header ? logoConfig.header.height : '75px'};
    display: flex;
    justify-content:space-between;
  `};
`

export const Wrapper = styled.div`
  display: flex;
`
