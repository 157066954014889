import React from 'react'
import Media from 'react-media'
import { breakpoints } from 'styles/mq'
import { Flex } from 'grid-styled'
import { Text } from 'components/text'
import Button from 'components/buttons/Button'
import LinkButton from 'components/buttons/LinkButton'
import { Trans } from '@lingui/macro'
import { companyInfo, color, features } from 'config/profile'
import OnlineSupportStatus from './OnlineSupportStatus/index.js'
import TrustPilotRating from './TrustPilotRating/index.js'
import Logo from 'components/Logo'

import {
  StyledHeader,
  HeaderBackground,
  Wrapper,
  LangContainer,
  LinkContainer,
} from './styled'
import LangButton from '../../../components/buttons/LangButton'

const isMobileQuery = {
  maxWidth: breakpoints.tablet - 1,
}

const Header = () => (
  <StyledHeader>
    <HeaderBackground>
      <Flex>
        <Logo />
        {features.TRUSTPILOT_HEADER && <TrustPilotRating />}
      </Flex>
      <Media query={isMobileQuery}>
        {isMobile => (
          <Wrapper>
            {!isMobile && features.CALL_HEADER && (
              <Flex alignItems="center" mr={15}>
                <Flex mr={15} flexDirection="column" alignItems="center">
                  <Text color={color.bannerTextColor}>
                    <Trans>Call us with your questions</Trans>
                  </Text>
                  <Text extraSmall color={color.bannerTextColor}>
                    {companyInfo.callingHours}
                  </Text>
                </Flex>

                <Button>{companyInfo.phone}</Button>
              </Flex>
            )}
            {features.ONLINE_SUPPORT_STATUS_HEADER && <OnlineSupportStatus />}
            {features.HEADER && features.HEADER.links && (
              <LinkContainer>
                {features.HEADER.links.map((link, index) => (
                  <LinkButton key={index} href={link.link} variant='header'>
                    {link.display}
                  </LinkButton>
                ))}
              </LinkContainer>
            )}
            {features.LOCALE_HEADER && (
              <LangContainer {...features.LOCALE_HEADER.props}>
                <Flex {...features.LOCALE_HEADER.props}>
                  <LangButton />
                </Flex>
              </LangContainer>
            )}
          </Wrapper>
        )}
      </Media>
    </HeaderBackground>
  </StyledHeader>
)

export default Header
