import styled, { css } from 'styled-components'
import { space, color } from 'styled-system'

import { color as colors } from 'styles/variables'

const variants = {
  default: {
    color: colors.link,
    '&:hover': {
      'text-decoration': 'underline',
    },
  },
  textLike: {
    color: colors.base,
    '&:hover': {
      color: colors.primary,
    },
  },
  header: {
    color: colors.bannerTextColor,
    '&:hover': {
      color: colors.primary,
    },
  },
}

export const linkStyles = css`
  cursor: pointer;

  ${({ variant }) => variants[variant] || variants.default}

  ${color};
`

export const LinkButton = styled.button`
  ${linkStyles};
  ${space};
`

LinkButton.defaultProps = {
  variant: 'default',
}
